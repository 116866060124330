import localedc68ac26 from '../../i18n/en_EN.json'
import locale48abd404 from '../../i18n/ja_JP.json'
import locale8f2a3e20 from '../../i18n/zh_CN.json'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"en"},
  vueI18nLoader: false,
  locales: [{"code":"en","iso":"en-US","file":"en_EN.json"},{"code":"jp","iso":"ja-JP","file":"ja_JP.json"},{"code":"cn","iso":"zh-CN","file":"zh_CN.json"}],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "no_prefix",
  lazy: false,
  langDir: "~/i18n/",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"en","iso":"en-US","file":"en_EN.json"},{"code":"jp","iso":"ja-JP","file":"ja_JP.json"},{"code":"cn","iso":"zh-CN","file":"zh_CN.json"}],
  localeCodes: ["en","jp","cn"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "e",
  3: "n",
  4: "_",
  5: "E",
  6: "N",
  7: ".",
  8: "j",
  9: "s",
  10: "o",
  11: "n",
  12: "\"",
  13: ":",
  14: "\"",
  15: ".",
  16: ".",
  17: "/",
  18: "i",
  19: "1",
  20: "8",
  21: "n",
  22: "/",
  23: "e",
  24: "n",
  25: "_",
  26: "E",
  27: "N",
  28: ".",
  29: "j",
  30: "s",
  31: "o",
  32: "n",
  33: "\"",
  34: ",",
  35: "\"",
  36: "j",
  37: "a",
  38: "_",
  39: "J",
  40: "P",
  41: ".",
  42: "j",
  43: "s",
  44: "o",
  45: "n",
  46: "\"",
  47: ":",
  48: "\"",
  49: ".",
  50: ".",
  51: "/",
  52: "i",
  53: "1",
  54: "8",
  55: "n",
  56: "/",
  57: "j",
  58: "a",
  59: "_",
  60: "J",
  61: "P",
  62: ".",
  63: "j",
  64: "s",
  65: "o",
  66: "n",
  67: "\"",
  68: ",",
  69: "\"",
  70: "z",
  71: "h",
  72: "_",
  73: "C",
  74: "N",
  75: ".",
  76: "j",
  77: "s",
  78: "o",
  79: "n",
  80: "\"",
  81: ":",
  82: "\"",
  83: ".",
  84: ".",
  85: "/",
  86: "i",
  87: "1",
  88: "8",
  89: "n",
  90: "/",
  91: "z",
  92: "h",
  93: "_",
  94: "C",
  95: "N",
  96: ".",
  97: "j",
  98: "s",
  99: "o",
  100: "n",
  101: "\"",
  102: "}",
}

export const localeMessages = {
  'en_EN.json': () => Promise.resolve(localedc68ac26),
  'ja_JP.json': () => Promise.resolve(locale48abd404),
  'zh_CN.json': () => Promise.resolve(locale8f2a3e20),
}
