const middleware = {}

middleware['auth'] = require('../middleware/auth.ts')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['checkout'] = require('../middleware/checkout.ts')
middleware['checkout'] = middleware['checkout'].default || middleware['checkout']

middleware['countryAccess'] = require('../middleware/countryAccess.ts')
middleware['countryAccess'] = middleware['countryAccess'].default || middleware['countryAccess']

middleware['examples'] = require('../middleware/examples.ts')
middleware['examples'] = middleware['examples'].default || middleware['examples']

middleware['featureAccess'] = require('../middleware/featureAccess.ts')
middleware['featureAccess'] = middleware['featureAccess'].default || middleware['featureAccess']

middleware['ifNotJapan'] = require('../middleware/ifNotJapan.ts')
middleware['ifNotJapan'] = middleware['ifNotJapan'].default || middleware['ifNotJapan']

middleware['is-preview'] = require('../middleware/is-preview.ts')
middleware['is-preview'] = middleware['is-preview'].default || middleware['is-preview']

middleware['log'] = require('../middleware/log.ts')
middleware['log'] = middleware['log'].default || middleware['log']

middleware['punchout-access'] = require('../middleware/punchout-access.ts')
middleware['punchout-access'] = middleware['punchout-access'].default || middleware['punchout-access']

middleware['punchout-login'] = require('../middleware/punchout-login.ts')
middleware['punchout-login'] = middleware['punchout-login'].default || middleware['punchout-login']

middleware['quote'] = require('../middleware/quote.ts')
middleware['quote'] = middleware['quote'].default || middleware['quote']

middleware['redirect'] = require('../middleware/redirect.ts')
middleware['redirect'] = middleware['redirect'].default || middleware['redirect']

middleware['signalstar'] = require('../middleware/signalstar.ts')
middleware['signalstar'] = middleware['signalstar'].default || middleware['signalstar']

middleware['validate'] = require('../middleware/validate.ts')
middleware['validate'] = middleware['validate'].default || middleware['validate']

middleware['verified-account'] = require('../middleware/verified-account.ts')
middleware['verified-account'] = middleware['verified-account'].default || middleware['verified-account']

middleware['with-data'] = require('../middleware/with-data.ts')
middleware['with-data'] = middleware['with-data'].default || middleware['with-data']

middleware['with-exception-routes'] = require('../middleware/with-exception-routes.ts')
middleware['with-exception-routes'] = middleware['with-exception-routes'].default || middleware['with-exception-routes']

export default middleware
