import Vue from 'vue'

import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'

/* Plugins */

import nuxt_plugin_plugin_269cdb8d from 'nuxt_plugin_plugin_269cdb8d' // Source: ./components/plugin.js (mode: 'all')
import nuxt_plugin_sentryserver_2935c232 from 'nuxt_plugin_sentryserver_2935c232' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_f4a8a542 from 'nuxt_plugin_sentryclient_f4a8a542' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_plugin_4effb6b5 from 'nuxt_plugin_plugin_4effb6b5' // Source: ./composition-api/plugin.mjs (mode: 'all')
import nuxt_plugin_toast_a7cb025c from 'nuxt_plugin_toast_a7cb025c' // Source: ./toast.js (mode: 'client')
import nuxt_plugin_pluginutils_43386678 from 'nuxt_plugin_pluginutils_43386678' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginrouting_0cb935c3 from 'nuxt_plugin_pluginrouting_0cb935c3' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_619b76e8 from 'nuxt_plugin_pluginmain_619b76e8' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_recaptcha_70baf712 from 'nuxt_plugin_recaptcha_70baf712' // Source: ./recaptcha.js (mode: 'all')
import nuxt_plugin_gtm_640725b9 from 'nuxt_plugin_gtm_640725b9' // Source: ./gtm.js (mode: 'all')
import nuxt_plugin_image_6a1da1c4 from 'nuxt_plugin_image_6a1da1c4' // Source: ./image.js (mode: 'all')
import nuxt_plugin_axios_402b9bba from 'nuxt_plugin_axios_402b9bba' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_apollomodule_15362aef from 'nuxt_plugin_apollomodule_15362aef' // Source: ./apollo-module.js (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_75792bcb from 'nuxt_plugin_cookieuniversalnuxt_75792bcb' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_pinoclient_9ffdfc8c from 'nuxt_plugin_pinoclient_9ffdfc8c' // Source: ./pino.client.js (mode: 'client')
import nuxt_plugin_pinoserver_15ba7342 from 'nuxt_plugin_pinoserver_15ba7342' // Source: ./pino.server.js (mode: 'server')
import nuxt_plugin_deviceplugin_0d8af6ae from 'nuxt_plugin_deviceplugin_0d8af6ae' // Source: ./device.plugin.js (mode: 'all')
import nuxt_plugin_distpluginb6194da4_22621648 from 'nuxt_plugin_distpluginb6194da4_22621648' // Source: ./dist.plugin.b6194da4.mjs (mode: 'all')
import nuxt_plugin_workbox_0ff6aa7f from 'nuxt_plugin_workbox_0ff6aa7f' // Source: ./workbox.js (mode: 'client')
import nuxt_plugin_metaplugin_7af90292 from 'nuxt_plugin_metaplugin_7af90292' // Source: ./pwa/meta.plugin.js (mode: 'all')
import nuxt_plugin_iconplugin_7fa0a806 from 'nuxt_plugin_iconplugin_7fa0a806' // Source: ./pwa/icon.plugin.js (mode: 'all')
import nuxt_plugin_nuxtplugin2a796ace_d0454cf2 from 'nuxt_plugin_nuxtplugin2a796ace_d0454cf2' // Source: ./nuxt.plugin.2a796ace.js (mode: 'all')
import nuxt_plugin_vuejsmodal_a01fef9e from 'nuxt_plugin_vuejsmodal_a01fef9e' // Source: ../plugins/vue-js-modal.js (mode: 'all')
import nuxt_plugin_vclickoutside_12eb1bf8 from 'nuxt_plugin_vclickoutside_12eb1bf8' // Source: ../plugins/v-click-outside.js (mode: 'all')
import nuxt_plugin_vuebrowserupdateclient_efbee3dc from 'nuxt_plugin_vuebrowserupdateclient_efbee3dc' // Source: ../plugins/vue-browserupdate.client.js (mode: 'client')
import nuxt_plugin_vueslickcaroussel_f0bc1f70 from 'nuxt_plugin_vueslickcaroussel_f0bc1f70' // Source: ../plugins/vue-slick-caroussel.js (mode: 'all')
import nuxt_plugin_piniapersistedstatepluginclient_fb4bb53e from 'nuxt_plugin_piniapersistedstatepluginclient_fb4bb53e' // Source: ../plugins/pinia-persistedstate.plugin.client.ts (mode: 'client')
import nuxt_plugin_logservice_082e2777 from 'nuxt_plugin_logservice_082e2777' // Source: ../plugins/log-service.ts (mode: 'all')
import nuxt_plugin_gtmclient_3751e07e from 'nuxt_plugin_gtmclient_3751e07e' // Source: ../plugins/gtm.client.ts (mode: 'client')
import nuxt_plugin_commercetools_97d9a27a from 'nuxt_plugin_commercetools_97d9a27a' // Source: ../plugins/commercetools.ts (mode: 'all')
import nuxt_plugin_payment_2b047bb2 from 'nuxt_plugin_payment_2b047bb2' // Source: ../plugins/payment.js (mode: 'all')
import nuxt_plugin_pdf_4675901e from 'nuxt_plugin_pdf_4675901e' // Source: ../plugins/pdf.ts (mode: 'all')
import nuxt_plugin_atgclient_22e388f2 from 'nuxt_plugin_atgclient_22e388f2' // Source: ../plugins/atg.client.ts (mode: 'client')
import nuxt_plugin_pimclient_454afff2 from 'nuxt_plugin_pimclient_454afff2' // Source: ../plugins/pim.client.ts (mode: 'client')
import nuxt_plugin_cstApi_43efd17e from 'nuxt_plugin_cstApi_43efd17e' // Source: ../plugins/cstApi.ts (mode: 'all')
import nuxt_plugin_signalStar_0a62cc1d from 'nuxt_plugin_signalStar_0a62cc1d' // Source: ../plugins/signalStar.ts (mode: 'all')
import nuxt_plugin_cloudflare_3222c3c4 from 'nuxt_plugin_cloudflare_3222c3c4' // Source: ../plugins/cloudflare.ts (mode: 'all')
import nuxt_plugin_featureFlagsPluginclient_d5e72764 from 'nuxt_plugin_featureFlagsPluginclient_d5e72764' // Source: ../plugins/featureFlagsPlugin.client.ts (mode: 'client')
import nuxt_plugin_featureFlagsPluginserver_0a744454 from 'nuxt_plugin_featureFlagsPluginserver_0a744454' // Source: ../plugins/featureFlagsPlugin.server.ts (mode: 'server')
import nuxt_plugin_user_34ef1952 from 'nuxt_plugin_user_34ef1952' // Source: ../plugins/api/user.ts (mode: 'all')
import nuxt_plugin_app_784ba73c from 'nuxt_plugin_app_784ba73c' // Source: ../plugins/app.ts (mode: 'all')
import nuxt_plugin_translation_4158d3d2 from 'nuxt_plugin_translation_4158d3d2' // Source: ../plugins/translation.ts (mode: 'all')
import nuxt_plugin_sfchatbotclient_bfddd982 from 'nuxt_plugin_sfchatbotclient_bfddd982' // Source: ../plugins/sf-chatbot.client.ts (mode: 'client')
import nuxt_plugin_plugin_2f971b47 from 'nuxt_plugin_plugin_2f971b47' // Source: ../modules/contentstack/plugin (mode: 'all')
import nuxt_plugin_image_f3fe9e9a from 'nuxt_plugin_image_f3fe9e9a' // Source: ../modules/providers/image/image.plugin (mode: 'all')
import nuxt_plugin_meta_994089f2 from 'nuxt_plugin_meta_994089f2' // Source: ./composition-api/meta.mjs (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root.$options.$nuxt
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext, config)

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"bodyAttrs":{"data-test-id":"body"},"title":"CST","titleTemplate":"%s | Cell Signaling Technology","meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":""},{"name":"format-detection","content":"telephone=no"},{"hid":"og:site_name","property":"og:site_name","content":"Cell Signaling Technology"},{"hid":"twitter:site","name":"twitter:site","content":"@cellsignal"},{"hid":"apple-mobile-web-app-title","name":"apple-mobile-web-app-title","content":"CST | Cell Signaling Technology"}],"link":[],"style":[],"script":[{"hid":"gtm-script","innerHTML":"if(!window._gtm_init){window._gtm_init=1;(function(w,n,d,m,e,p){w[d]=(w[d]==1||n[d]=='yes'||n[d]==1||n[m]==1||(w[e]&&w[e][p]&&w[e][p]()))?1:0})(window,navigator,'doNotTrack','msDoNotTrack','external','msTrackingProtectionEnabled');(function(w,d,s,l,x,y){w[x]={};w._gtm_inject=function(i){if(w.doNotTrack||w[x][i])return;w[x][i]=1;w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s);j.defer=true;j.src='https:\u002F\u002Fwww.googletagmanager.com\u002Fgtm.js?id='+i;f.parentNode.insertBefore(j,f);}})(window,document,'script','dataLayer','_gtm_ids','_gtm_inject')}"}],"noscript":[{"hid":"gtm-noscript","pbody":true,"innerHTML":""}],"__dangerouslyDisableSanitizersByTagID":{"gtm-script":["innerHTML"],"gtm-noscript":["innerHTML"]}},

    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_269cdb8d === 'function') {
    await nuxt_plugin_plugin_269cdb8d(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_sentryserver_2935c232 === 'function') {
    await nuxt_plugin_sentryserver_2935c232(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_f4a8a542 === 'function') {
    await nuxt_plugin_sentryclient_f4a8a542(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_4effb6b5 === 'function') {
    await nuxt_plugin_plugin_4effb6b5(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_toast_a7cb025c === 'function') {
    await nuxt_plugin_toast_a7cb025c(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_43386678 === 'function') {
    await nuxt_plugin_pluginutils_43386678(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_0cb935c3 === 'function') {
    await nuxt_plugin_pluginrouting_0cb935c3(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_619b76e8 === 'function') {
    await nuxt_plugin_pluginmain_619b76e8(app.context, inject)
  }

  if (typeof nuxt_plugin_recaptcha_70baf712 === 'function') {
    await nuxt_plugin_recaptcha_70baf712(app.context, inject)
  }

  if (typeof nuxt_plugin_gtm_640725b9 === 'function') {
    await nuxt_plugin_gtm_640725b9(app.context, inject)
  }

  if (typeof nuxt_plugin_image_6a1da1c4 === 'function') {
    await nuxt_plugin_image_6a1da1c4(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_402b9bba === 'function') {
    await nuxt_plugin_axios_402b9bba(app.context, inject)
  }

  if (typeof nuxt_plugin_apollomodule_15362aef === 'function') {
    await nuxt_plugin_apollomodule_15362aef(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_75792bcb === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_75792bcb(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_pinoclient_9ffdfc8c === 'function') {
    await nuxt_plugin_pinoclient_9ffdfc8c(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_pinoserver_15ba7342 === 'function') {
    await nuxt_plugin_pinoserver_15ba7342(app.context, inject)
  }

  if (typeof nuxt_plugin_deviceplugin_0d8af6ae === 'function') {
    await nuxt_plugin_deviceplugin_0d8af6ae(app.context, inject)
  }

  if (typeof nuxt_plugin_distpluginb6194da4_22621648 === 'function') {
    await nuxt_plugin_distpluginb6194da4_22621648(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_workbox_0ff6aa7f === 'function') {
    await nuxt_plugin_workbox_0ff6aa7f(app.context, inject)
  }

  if (typeof nuxt_plugin_metaplugin_7af90292 === 'function') {
    await nuxt_plugin_metaplugin_7af90292(app.context, inject)
  }

  if (typeof nuxt_plugin_iconplugin_7fa0a806 === 'function') {
    await nuxt_plugin_iconplugin_7fa0a806(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtplugin2a796ace_d0454cf2 === 'function') {
    await nuxt_plugin_nuxtplugin2a796ace_d0454cf2(app.context, inject)
  }

  if (typeof nuxt_plugin_vuejsmodal_a01fef9e === 'function') {
    await nuxt_plugin_vuejsmodal_a01fef9e(app.context, inject)
  }

  if (typeof nuxt_plugin_vclickoutside_12eb1bf8 === 'function') {
    await nuxt_plugin_vclickoutside_12eb1bf8(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuebrowserupdateclient_efbee3dc === 'function') {
    await nuxt_plugin_vuebrowserupdateclient_efbee3dc(app.context, inject)
  }

  if (typeof nuxt_plugin_vueslickcaroussel_f0bc1f70 === 'function') {
    await nuxt_plugin_vueslickcaroussel_f0bc1f70(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_piniapersistedstatepluginclient_fb4bb53e === 'function') {
    await nuxt_plugin_piniapersistedstatepluginclient_fb4bb53e(app.context, inject)
  }

  if (typeof nuxt_plugin_logservice_082e2777 === 'function') {
    await nuxt_plugin_logservice_082e2777(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_gtmclient_3751e07e === 'function') {
    await nuxt_plugin_gtmclient_3751e07e(app.context, inject)
  }

  if (typeof nuxt_plugin_commercetools_97d9a27a === 'function') {
    await nuxt_plugin_commercetools_97d9a27a(app.context, inject)
  }

  if (typeof nuxt_plugin_payment_2b047bb2 === 'function') {
    await nuxt_plugin_payment_2b047bb2(app.context, inject)
  }

  if (typeof nuxt_plugin_pdf_4675901e === 'function') {
    await nuxt_plugin_pdf_4675901e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_atgclient_22e388f2 === 'function') {
    await nuxt_plugin_atgclient_22e388f2(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_pimclient_454afff2 === 'function') {
    await nuxt_plugin_pimclient_454afff2(app.context, inject)
  }

  if (typeof nuxt_plugin_cstApi_43efd17e === 'function') {
    await nuxt_plugin_cstApi_43efd17e(app.context, inject)
  }

  if (typeof nuxt_plugin_signalStar_0a62cc1d === 'function') {
    await nuxt_plugin_signalStar_0a62cc1d(app.context, inject)
  }

  if (typeof nuxt_plugin_cloudflare_3222c3c4 === 'function') {
    await nuxt_plugin_cloudflare_3222c3c4(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_featureFlagsPluginclient_d5e72764 === 'function') {
    await nuxt_plugin_featureFlagsPluginclient_d5e72764(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_featureFlagsPluginserver_0a744454 === 'function') {
    await nuxt_plugin_featureFlagsPluginserver_0a744454(app.context, inject)
  }

  if (typeof nuxt_plugin_user_34ef1952 === 'function') {
    await nuxt_plugin_user_34ef1952(app.context, inject)
  }

  if (typeof nuxt_plugin_app_784ba73c === 'function') {
    await nuxt_plugin_app_784ba73c(app.context, inject)
  }

  if (typeof nuxt_plugin_translation_4158d3d2 === 'function') {
    await nuxt_plugin_translation_4158d3d2(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sfchatbotclient_bfddd982 === 'function') {
    await nuxt_plugin_sfchatbotclient_bfddd982(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_2f971b47 === 'function') {
    await nuxt_plugin_plugin_2f971b47(app.context, inject)
  }

  if (typeof nuxt_plugin_image_f3fe9e9a === 'function') {
    await nuxt_plugin_image_f3fe9e9a(app.context, inject)
  }

  if (typeof nuxt_plugin_meta_994089f2 === 'function') {
    await nuxt_plugin_meta_994089f2(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    app,
    router
  }
}

export { createApp, NuxtError }
